import 'react-toastify/dist/ReactToastify.css';
import './../content/css/loading.css';
import './../content/css/miraeloading.css';
import '../../../../scss/app.scss';
import '../../../../scss/bootstrap.scss';
import '../../../../scss/icons.scss';
import '../content/css/font.scss';
import '../app/footer.scss';

import React, { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader';

import { IRootState } from 'app/shared/reducers';
import { getSession, logout } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import Header from 'app/shared/layout/header/header';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';

import Login from 'app/modules/login/login';
import LogLogin from 'app/modules/login/log-auth';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import NotFound from 'app/modules/not-found';
import ForgotPass from 'app/modules/account/forgot/forgot-password';
import ForgotUserID from 'app/modules/account/forgot/forgot-userid';
import useWindowDimensions from "app/shared/util/screen-dimension";
import SessionUtil from "app/config/SessionUtil";
import {Storage} from "react-jhipster";
import {AdminLog} from "app/modules/admin/admin-log";
import {Log} from "app/modules/log/log";
import LANG from "app/shared/util/lang-constant";
import moment from "moment";
import Healthcheck from "app/modules/healthcheck";
import IFrameLoading from './modules/form/revamp-data/IFrameLoading';

import firebase, { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';
import { fetchAndActivate } from 'firebase/remote-config';
import { getValue } from 'firebase/remote-config';
import { Console } from 'console';
import ReactGA from "react-ga4";
ReactGA.initialize("G-DZY78X2V4N");

const baseHref = document
  .querySelector('base')
  .getAttribute('href')
  .replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}

//handle deeplink
const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);
const deeplink = params.has('key') ? true : false;

const RoutesNoLogin = (deeplink: boolean) => (
    <div id="page-content">
        <ErrorBoundary>
            <Switch>
                <ErrorBoundaryRoute path="/healthcheck" component={Healthcheck}/>
                <ErrorBoundaryRoute path="/" component={Login} exact/>
                <ErrorBoundaryRoute path="/log" component={LogLogin}/>
                {/*<ErrorBoundaryRoute path="/log-admin" component={AdminLog} />*/}
                <ErrorBoundaryRoute path="/forgot-pass" component={ForgotPass}/>
                {/*<ErrorBoundaryRoute path="/forgot-userid" component={ForgotUserID} />*/}
                <ErrorBoundaryRoute path="/liveness/loading" exact component={IFrameLoading} />
                <ErrorBoundaryRoute component={NotFound}/>
            </Switch>
        </ErrorBoundary>
    </div>
);

export const App = (props: IAppProps) => {
  const { height, width } = useWindowDimensions();
  let refreshIntervalId = null;
  const [events, setEvents] = useState(['click', 'load', 'scroll']);

  let timeStamp;
  let doLogout: any = null;

  let resetTimer = () => {
    clearTimeout(doLogout);
    timeStamp = moment();
    if (Storage.local.get('isLoggedIn') == 'true') {
      Storage.local.set('lastTimeStamp', timeStamp);

      doLogout = setTimeout(() => {
        showToast();
        props.logout();
      }, 30 * 60000);
    } else {
      Storage.local.remove('lastTimeStamp');
    }
  };

  const firebaseConfig = {
    apiKey: 'AIzaSyAbGAuMVT3yPwxHkUx2hlmiiiPH-ILi-zI',
    authDomain: 'ooa3-prod.firebaseapp.com',
    projectId: 'ooa3-prod',
    storageBucket: 'ooa3-prod.appspot.com',
    messagingSenderId: '1038772671214',
    appId: '1:1038772671214:web:d6974fc220e892be4599ff',
  };

  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig();
  const remoteConfigUser = getRemoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 15000;
  remoteConfigUser.settings.minimumFetchIntervalMillis = 15000;

  const getFetched = async () => {
    const isFetched = await fetchAndActivate(remoteConfig);
    if (isFetched) {
      const valMenuTaxReport = getValue(
        remoteConfig,
        'menu_tax_report'
      ).asBoolean();
      // const valUserTaxReport = getValue(remoteConfig, 'list_user_tax_report')
      //   .asString;
      Storage.local.set('MENU_TAX_REPORT', valMenuTaxReport);
      // Storage.local.set('USER_TAX_REPORT', valUserTaxReport);
      console.log('MENU_TAX_REPORT', valMenuTaxReport);
      // console.log('MENU_TAX_REPORT', valUserTaxReport);
      // props.setMenuTaxReport(valMenuTaxReport);
      // props.setUserTaxReport(valUserTaxReport);
    }
  };

  // const getFetchedUser = async () => {
  //   const isFetched = await fetchAndActivate(remoteConfigUser);
  //   if (isFetched) {
  //     const valUserTaxReport = getValue(
  //       remoteConfigUser,
  //       'list_user_tax_report'
  //     ).asString();
  //     Storage.local.set('USER_TAX_REPORT', valUserTaxReport);
  //     // console.log('MENU_TAX_REPORT', valUserTaxReport);
  //     // props.setUserTaxReport(valUserTaxReport);
  //   }
  // };

  useEffect(() => {
    console.log('MASUK KE APP');
    getFetched();
    // getFetchedUser();
  }, []);

  useEffect(() => {
    Storage.local.get('isLoggedIn', 'false');
    props.getSession();

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    if (Storage.local.get('isLoggedIn') == 'true') {
      try {
        refreshIntervalId = setInterval(async () => {
          await fetch('services/uaadocservice/api/account');
        }, 4 * 60000);
      } catch (e) {
        console.log(e);
      }
    }
  }, [refreshIntervalId, events]);

  const showToast = () => {
    toast.error(LANG().SESSION_EXPIRED);
  };

  if (props.loading) {
    return (
      <>
        <div id="root">
          <div className="centerUp">
            <img src="./content/images/logoloading.png" />

            <div className="centerLoadingBar meter">
              <span className="progress"></span>
            </div>
          </div>
          <img
            src="./content/images/supportbymirae.png"
            className="centerDown"
          />
        </div>
      </>
    );
  } else {
    return (
      <div style={{ background: '#FFF', overflowX: 'hidden' }}>
        <Router basename={baseHref}>
          {/* Header */}
          {window.location.pathname != '/healthcheck' ? (
            <ErrorBoundary>
              <Header
                isAuthenticated={props.isAuthenticated}
                isAdmin={props.isAdmin}
                currentLocale={props.currentLocale}
                onLocaleChange={props.setLocale}
                ribbonEnv={props.ribbonEnv}
                isInProduction={props.isInProduction}
                isSwaggerEnabled={props.isSwaggerEnabled}
              />
            </ErrorBoundary>
          ) : (
            <></>
          )}
          <div className="col">
            {/* Body */}
            <ToastContainer
              position={toast.POSITION.TOP_LEFT}
              className="toastify-container"
              toastClassName="toastify-toast"
            />
            <div
              className="row app-container"
              style={
                width > 426
                  ? {
                      minHeight: '860px',
                      paddingTop:
                        window.location.pathname != '/healthcheck'
                          ? '60px'
                          : '0px',
                      marginBottom: '60px',
                    }
                  : { minHeight: '700px', height: '100%', paddingTop: '60px' }
              }
            >
              <div
                className="container-fluid view-container pb-4"
                id="app-view-container"
              >
                {/* Content */}
                {Storage.local.get('isLoggedIn', 'false') == 'true' ? (
                  <>
                    <div id="page-content">
                      <ErrorBoundary>
                        <AppRoutes />
                      </ErrorBoundary>
                    </div>
                  </>
                ) : (
                  <>{RoutesNoLogin(deeplink)}</>
                )}
              </div>
            </div>
            {/* Body */}

            {/* Footer */}
            {/*Small device*/}
            <div className="row d-block d-sm-block d-md-block d-lg-none d-xl-none">
              <footer style={{ backgroundColor: 'rgb(236, 239, 244)' }}>
                {/*Section Social Media*/}
                <section className="container d-flex justify-content-center justify-content-lg-between pt-4 border-bottom">
                  {/*Left*/}
                  <div className="my-auto">
                    <span style={{ fontSize: '14pt' }}>
                      Customer Service{' '}
                      <a href="tel:021150350">
                        <span style={{ color: '#043b72', fontWeight: 'bold' }}>
                          150350 (Indonesia)
                        </span>
                      </a>
                    </span>
                  </div>
                  {/*Left*/}

                  {/*Right*/}
                  <div className="col">
                    <div className="row">
                      <div className="social-media mt-2 text-right d-flex justify-content-end">
                        <a
                          href="https://www.facebook.com/miraeassetsekuritasid/?fref=ts"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a
                          href="https://twitter.com/search?q=MiraeAsset_ID"
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/miraeassetsekuritas_id/?igshid=h0gc23vvipkl"
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                        <a
                          href="https://www.youtube.com/channel/UCbgUIA1udecuOCfDaOFkZ1w"
                          target="_blank"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  {/*Right*/}
                </section>
                {/*Section Social Media*/}

                {/* Section: Links  */}
                <section>
                  <div className="container text-center text-md-start">
                    {/* Grid row */}
                    <div className="row">
                      {/* Grid column */}
                      <div className="col-6 mx-auto mb-4 mt-3">
                        {/* Content */}
                        <div className="col">
                          <div className="row">
                            <div className="d-flex align-items-start">
                              <img
                                src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-indonesia.png"
                                width="152"
                                height="48"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <p
                              className="mt-2"
                              style={{ fontSize: '7pt', textAlign: 'left' }}
                            >
                              Mirae Asset Sekuritas | Member of Indonesia Stock
                              Exchange
                              <br />
                              District 8, Treasury Tower 50th floor SCBD lot 28,
                              <br />
                              Jl. Jend. Sudirman Kav.52-54 Jakarta 12190.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* Grid column */}

                      {/* Grid column */}
                      <div className="col-6 mx-auto mb-4">
                        <div
                          className="row mt-4"
                          style={{
                            fontSize: '8pt',
                            textAlign: 'right',
                            paddingRight: '10px',
                          }}
                        >
                          PT Mirae Asset Sekuritas Indonesia terdaftar dan
                          diawasi oleh Otoritas Jasa Keuangan (OJK)
                        </div>
                        <div
                          className="row mt-2"
                          style={{ paddingRight: '4px' }}
                        >
                          <div className="col text-center">
                            <a
                              href="https://ojk.go.id/id/Default.aspx"
                              target="blank"
                              className="partner"
                            >
                              {' '}
                              <img
                                src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-ojk.png"
                                width="40"
                              />
                            </a>
                          </div>
                          <div className="col text-center">
                            <a
                              href="https://www.idx.co.id/"
                              target="blank"
                              className="partner"
                            >
                              {' '}
                              <img
                                src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-idx.png"
                                width="40"
                              />
                            </a>
                          </div>
                          <div className="col text-center">
                            <a
                              href="https://www.idclear.co.id"
                              target="blank"
                              className="partner"
                            >
                              {' '}
                              <img
                                src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-id-clear.png"
                                width="40"
                              />{' '}
                            </a>
                          </div>
                          <div className="col text-center">
                            <a
                              href="https://www.ksei.co.id/"
                              target="blank"
                              className="partner"
                            >
                              {' '}
                              <img
                                src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-ksei.png"
                                width="40"
                              />
                            </a>
                          </div>
                          <div className="col text-center">
                            <a
                              href="https://www.indonesiasipf.co.id/"
                              target="blank"
                              className="partner"
                            >
                              {' '}
                              <img
                                src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-sipf.png"
                                width="40"
                              />
                            </a>
                          </div>
                          <div className="col text-center">
                            <a className="partner">
                              {' '}
                              <img
                                src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-yuk.png"
                                width="40"
                              />
                            </a>
                          </div>
                          <div className="col text-center">
                            <a className="partner">
                              {' '}
                              <img
                                src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-inklusi.png"
                                width="40"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* Grid column */}
                    </div>
                    {/* Grid row */}
                  </div>
                </section>
                {/* Section: Links  */}
              </footer>
            </div>
            {/*Small device*/}
            {/*Large device*/}
            <div className="row d-none d-sm-none d-md-none d-lg-block d-xl-block">
              <footer style={{ backgroundColor: 'rgb(236, 239, 244)' }}>
                {/*Section Social Media*/}
                <section className="container d-flex justify-content-center justify-content-lg-between pt-4 border-bottom">
                  {/*Left*/}
                  <div className="my-auto d-none d-lg-block">
                    <span style={{ fontSize: '14pt' }}>
                      Customer Service{' '}
                      <a href="tel:021150350">
                        <span style={{ color: '#043b72', fontWeight: 'bold' }}>
                          150350 (Indonesia)
                        </span>
                      </a>
                    </span>
                  </div>
                  {/*Left*/}

                  {/*Right*/}
                  <div className="row">
                    <div className="social-media mt-2 text-right d-flex justify-content-end">
                      <a
                        href="https://www.facebook.com/miraeassetsekuritasid/?fref=ts"
                        target="_blank"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        href="https://twitter.com/search?q=MiraeAsset_ID"
                        target="_blank"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/miraeassetsekuritas_id/?igshid=h0gc23vvipkl"
                        target="_blank"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCbgUIA1udecuOCfDaOFkZ1w"
                        target="_blank"
                      >
                        <i className="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>

                  {/*Right*/}
                </section>
                {/*Section Social Media*/}

                {/* Section: Links  */}
                <section>
                  <div className="container text-center text-md-start">
                    {/* Grid row */}
                    <div className="row">
                      {/* Grid column */}
                      <div className="col-6 mx-auto mb-4 mt-3">
                        {/* Content */}
                        <img
                          src={
                            'https://cdn.miraeasset.co.id/web-corporate/mirae-logo-indonesia.png'
                          }
                          width="152"
                          height="48"
                        />
                        <p className="mt-2" style={{ fontSize: '7pt' }}>
                          Mirae Asset Sekuritas | Member of Indonesia Stock
                          Exchange
                          <br />
                          District 8, Treasury Tower 50th floor SCBD lot 28,
                          <br />
                          Jl. Jend. Sudirman Kav.52-54 Jakarta 12190.
                        </p>
                      </div>
                      {/* Grid column */}

                      {/* Grid column */}
                      <div className="col-6 mx-auto mb-4 d-flex">
                        <div className="col">
                          <div className="row">
                            <p
                              className="mt-4 text-right d-flex justify-content-end"
                              style={{ fontSize: '8pt' }}
                            >
                              PT Mirae Asset Sekuritas Indonesia terdaftar dan
                              diawasi oleh Otoritas Jasa Keuangan (OJK)
                            </p>
                          </div>
                          <div className="row">
                            <div className="partner-group d-flex justify-content-end">
                              <a
                                href="https://ojk.go.id/id/Default.aspx"
                                target="blank"
                                className="partner"
                              >
                                {' '}
                                <img
                                  src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-ojk.png"
                                  width="80"
                                />
                              </a>
                              <a
                                href="https://www.idx.co.id/"
                                target="blank"
                                className="partner"
                              >
                                {' '}
                                <img
                                  src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-idx.png"
                                  width="80"
                                />
                              </a>
                              <a
                                href="https://www.idclear.co.id"
                                target="blank"
                                className="partner"
                              >
                                {' '}
                                <img
                                  src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-id-clear.png"
                                  width="80"
                                />{' '}
                              </a>
                              <a
                                href="https://www.ksei.co.id/"
                                target="blank"
                                className="partner"
                              >
                                {' '}
                                <img
                                  src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-ksei.png"
                                  width="80"
                                />
                              </a>
                              <a
                                href="https://www.indonesiasipf.co.id/"
                                target="blank"
                                className="partner"
                              >
                                {' '}
                                <img
                                  src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-sipf.png"
                                  width="80"
                                />
                              </a>
                              <a className="partner">
                                {' '}
                                <img
                                  src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-yuk.png"
                                  width="80"
                                />
                              </a>
                              <a className="partner">
                                {' '}
                                <img
                                  src="https://cdn.miraeasset.co.id/web-corporate/mirae-logo-partner-inklusi.png"
                                  width="80"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Grid column */}
                    </div>
                    {/* Grid row */}
                  </div>
                </section>
                {/* Section: Links  */}
              </footer>
            </div>
            {/*Large device*/}
            {/* Footer */}
          </div>
        </Router>
        {/*{Storage.local.get("isLoggedIn") == "true"?
            <SessionUtil {...props} showToast={showToast} /> : <></>
          }*/}
      </div>
    );
  }
};

const mapStateToProps = ({
  authentication,
  applicationProfile,
  locale,
}: IRootState) => ({
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  loading: authentication.loading,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [
    AUTHORITIES.ADMIN,
  ]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
  // isTaxReportShow: authentication.isTaxReportShow,
});

const mapDispatchToProps = {
  setLocale,
  getSession,
  getProfile,
  logout,
  // setMenuTaxReport,
  // setUserTaxReport,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
