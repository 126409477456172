import './login.scss';
import { Storage } from 'react-jhipster';

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import { login } from 'app/shared/reducers/authentication';

import {Row, Col, ModalBody, Modal} from 'reactstrap';
import { AvForm, AvField} from 'availity-reactstrap-validation';
import { Link } from 'react-router-dom';
import DarkPurpleButton from 'app/shared/layout/my-button/dark-purple-button';
import { useFormik } from 'formik';
import LANG from "app/shared/util/lang-constant";
import GeneralSettingConstant from "app/shared/util/general-settings-constant";
import Axios from "axios";
import EndPoint from "app/shared/util/api-constant";
import SuccessIllustration from "app/component/success-illustration";
import FailedIllustration from "app/component/failed-illustration";
import ReactGA from "react-ga4";


export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export const Loginx = (props: ILoginProps) => {

  const handleLogin = async (username, password, isEmployee) => {
    await props.login(username, password, isEmployee)
    setLoadingSubmit(false)
  };

  const { location, isAuthenticated } = props;
  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search } };

  const icPassEye = "fa fa-fw fa-eye field-icon";
  const icSlashPassEye = "fa fa-eye fa-eye-slash";
  const [getIcTogglePassword, setIcTogglePassword] = useState(icSlashPassEye);
  const [getTypeTogglePassword, setTypeTogglePassword] = useState("password");
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  if (Storage.local.get("isLoggedIn", "false") == "true") {
    return <Redirect to={from} />;
  }

  const loginState = useFormik({
    initialValues: {   
        username: "",
        password: "",
    },
    onSubmit: values => {
      const bodyJson = JSON.parse(JSON.stringify(values, null, 2));
      handleLogin(bodyJson['username'], bodyJson['password'], GeneralSettingConstant().ROLE_USER);
    }
  });

  const togglePassword = () => {
    if(getIcTogglePassword == icSlashPassEye){
        setIcTogglePassword(icPassEye);
        setTypeTogglePassword("text");
    } else {
      setIcTogglePassword(icSlashPassEye);
        setTypeTogglePassword("password");
    }
  }

    const MYURL = EndPoint();

    const [isOpenModal, setOpenModal] = useState(false);
    const [getModalTitleMessage, setModalTitleMessage] = useState("");
    const [getModalDescMessage, setModalDescMessage] = useState("");
    const [getModalIllustrationStatus, setModalIllustrationStatus] = useState(false);

    const handleIsAvailableChangePassword = () => {
        Axios.post(MYURL.AVAILABLE_PASSWORD_PIN).then(
            res => {
                if(res.status == 200) {
                    if(res.data.code == "OK") {
                        window.location.href = "/forgot-pass"
                    } else {
                        setModalIllustrationStatus(false);
                        setOpenModal(true);
                        setModalTitleMessage(LANG().FAILED);
                        setModalDescMessage(res.data.message);
                    }
                } else {
                    setModalIllustrationStatus(false);
                    setOpenModal(true);
                    setModalTitleMessage(LANG().FAILED);
                    setModalDescMessage(LANG().FORM.ERROR.CANNOT_CHANGE_PASSWORD);
                }
            }
        ).catch(
            res => {
                setModalIllustrationStatus(false);
                setOpenModal(true);
                setModalTitleMessage(LANG().FAILED);
                setModalDescMessage(LANG().FORM.ERROR.CANNOT_CHANGE_PASSWORD);
            }
        )
    }

    return (
    <>
    <div style={{background: '#fff'}} className="mt-lg-5 mt-md-5 py-5 container">

        <Modal style={{top:"25%"}} isOpen={isOpenModal}>
            <ModalBody>
                <div className="text-center mb-4">{getModalIllustrationStatus? <SuccessIllustration/> : <FailedIllustration/>}</div>
                <p style={{fontSize:"20px"}} className="text-center mb-2 font-m-500">{getModalTitleMessage}</p>
                <p style={{fontSize:"14px"}} className="text-center mb-2 font-r-400">{getModalDescMessage}</p>
                <div className="d-flex justify-content-center" onClick={ () => {
                    setOpenModal(false);
                    setModalTitleMessage("");
                    setModalDescMessage("");
                    // window.location.href="/";
                }}>
                    <DarkPurpleButton text='OK' width="20%"/>
                </div>
            </ModalBody>
        </Modal>

        {/*<pre>*/}
        {/*    {encryptJson({userid: "123456", otp: "654321"})}*/}
        {/*    <br/>*/}
        {/*    {decryptJson(encryptJson({userid: "123456", otp: "654321"}))}*/}
        {/*</pre>*/}

      <AvForm>
          <Row>
              <Col md="6" className="pb-5 px-3 mr-5">

                  {/*Small device*/}
                  <img src="../../content/images/kyc_img_frontwebdisplay.png" width={300} className="d-block d-sm-block d-md-block d-lg-none d-xl-none" />
                  {/*Small device*/}

                  {/*Large device*/}
                  <img src="../../content/images/kyc_img_frontwebdisplay.png" width={500} className="d-none d-sm-none d-md-none d-lg-block d-xl-block" />
                  {/*Large device*/}

                  <div className='my-4'>
                  <div className='font-weight-bold' style={{fontSize:"24pt"}}>
                      {LANG().LOGIN_TITLE_TAGLINE}
                  </div>
                  <p className='brown-text' style={{fontSize:"16pt"}}>
                      {LANG().LOGIN_DESC_TAGLINE}
                  </p>  
                  </div>
              </Col>
              
              <Col md="5" className="card py-5 px-4 login-box">
                <Row style={{paddingLeft:40, paddingRight:40}}>
                <Col md="12">
                  <br></br><br></br>
                  <AvField
                    name="username"
                    label={LANG().FORM.USERNAME.LABEL}
                    value={loginState.values.username}
                    onChange={loginState.handleChange}
                    placeholder={LANG().FORM.USERNAME.PH}
                    validate={{required: {value: true, errorMessage: LANG().FORM.ERROR.REQUIRED}}}
                    autoFocus
                  />
                  <Row>
                  <div className="input-title">
                    <div className="input-id-title">{LANG().FORM.PASSWORD.LABEL}</div>
                  </div>
                  <Col className='mb-2'>
                    <input
                      name="password"
                      type={getTypeTogglePassword}
                      value={loginState.values.password}
                      onChange={loginState.handleChange}
                      className="form-control"
                      // placeholder={LANG().FORM.PASSWORD.PH}
                    />
                  </Col>
                  <Col onClick={togglePassword} style={{cursor:"pointer", maxWidth:"10px", marginTop:"8px", marginBottom:"auto", marginLeft:"-50px"}}>
                        <span className={getIcTogglePassword}></span>
                  </Col>
                  </Row>

                </Col>
                </Row>
      
                <Row style={{paddingLeft:40, paddingRight:40}}>
                <Col>

                </Col>
            
                <Col>
                  <div className='float-right d-flex flex-row-reverse mt-1 mb-1'>
                    {/*<Link to="/forgot-pass" style={{color: '#F37F26'}} className="my-2">
                        {LANG().FORGOT_PASS}
                    </Link>*/}

                      <span className='btn-forgot-pass' onClick={(e) => {
                          e.preventDefault();
                          ReactGA.event({
                            category: "forgotPassCategory",
                            action: "Klik_Tombol_Forgot_Pass",
                            label: "Klik_Tombol_Forgot_Pass", // optional
                            value: 12, // optional, must be a number
                            nonInteraction: true, // optional, true/false
                            transport: "xhr", // optional, beacon/xhr/image
                          });
                          handleIsAvailableChangePassword()
                      }} style={{color:'rgb(243, 127, 38)', cursor:'pointer'}} >{LANG().FORGOT_PASS}</span>

                  </div>
                </Col>
                </Row>
          
                <Row style={{marginLeft:40, marginRight:40, marginTop:10}}>
                  <DarkPurpleButton disabled={loadingSubmit} onClick={(e) => {
                    e.preventDefault();
                    setLoadingSubmit(true);
                    ReactGA.event({
                      category: "loginCategory",
                      action: "Klik_Tombol_Login",
                      label: "Klik_Tombol_Login", // optional
                      value: 99, // optional, must be a number
                      nonInteraction: true, // optional, true/false
                      transport: "xhr", // optional, beacon/xhr/image
                    });
                    loginState.handleSubmit()
                  }} text={LANG().BUTTON.SIGNIN} />
                </Row>

              </Col>
          </Row>
         
      </AvForm>
   </div>
   </>
  );
};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Loginx);
