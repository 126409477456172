import NotFoundIllustration from "app/component/notfound-illustration";
import React from "react";
import { connect } from "react-redux";
import LANG from "app/shared/util/lang-constant";
import { useHistory } from "react-router";

export const NotFound = (props) => {

    const history = useHistory()

    return ( 
        <div style={{height: '90vh', width: '100%', verticalAlign: 'middle', alignContent: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', flexDirection: 'column'}}>
        <div className="px-5 pt-4 text-center" >
    
        <NotFoundIllustration />
        <br></br>

        </div >
        <div className='my-3 text-center'>
            <h2 className='text-center' style={{color: '#404040'}}>{LANG().NOT_FOUND_TITLE}</h2>
            <p className='text-center'>{LANG().NOT_FOUND_DESC}</p>
            <button className='btn btn-primary' style={{backgroundColor:"#F37F26"}} onClick={() => history.go(0)}>{LANG().BUTTON.BACK}</button>
        </div>
        </div>
        
    );

}

export default connect()(NotFound);